// Autocomplete inputs
.raincoat-autocomplete {
  .mdc-text-field {
    .autocomplete-search-clear {
      // allow the icon to capture pointer events when under another element
      pointer-events: all;
      cursor: pointer;
    }
  }
}
