.raincoat-autocomplete {
  .mdc-text-field {
    height: 48px;
    width: 100%;
    margin-bottom: 0;
  }
  .mdc-list {
    padding: 0;
    .mdc-list-item {
      padding-left: 8px;
      &.mdc-list-item--with-two-lines {
        height: auto;
        padding-bottom: 6px;
        .mdc-list-item__primary-text::before {
          height: 20px;
        }
      }
    }
  }
}
